@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  background-color: rgb(0, 68, 146);
  color: #fff;
}

.header-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.logo_section {
  line-height: 15px;
}

li {
  list-style: none;
}

.nav-buttons {
  font-size: 18px;
}

.menu {
  display: flex;
}

.menu_item {
  padding: 0 20px;
}

a {
  color: #fff;
}

.headshot {
  width: 325px;
  height: 400px;
}

.skills-title {
  padding-right: 76px;
}

.logo {
  width: 25px;
  height: 52px;
}

.github-logo {
  width: 50px;
  height: 50px;
}

.github-logo-btn {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.github-text-footer {
  position: absolute;
}

.image-size, 
.image-overlay {
  width: 350px;
  height: 200px;
}

.image-overlay:after, 
.image-overlay:before {
  position: absolute;
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
.image-overlay:after {
  content: '\A';
  width: 350px;
  height: 168px;
  top: 0; left:0;
  background:rgba(0,0,0,0.8);
}
.image-overlay:before {
  content: attr(data-overlay-title);
  height: 175px;
  color: #fff;
  font-size: 28px;
  z-index: 1;
  bottom: 0;
  padding: 2px 100px;
  font-weight: bold;
  text-align: center;
  /* background: #f00; */
  box-sizing: border-box;
  -moz-box-sizing:border-box;
}

.image-overlay:hover:after, 
.image-overlay:hover:before {
  opacity: 1;
}

.open-modal-button {
  opacity: 0;
  position: relative;
  top: -45px;
  margin: auto;
}

.image-overlay:hover > .open-modal-button, 
.open-modal-button:hover {
  opacity: 1;
  z-index: 999;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.contact-card {
  width: 355px;
  height: 136px;
}